/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

import SEO from '../components/seo'

const Lol = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const { width, height } = useWindowSize()

  return (
    <div className="w-full bg-gray-900">
      <SEO title={siteTitle} keywords={[`baby`, `boy`, `girl`, `longhurst`]} />
      <div className="h-screen flex flex-col justify-center">
        <Confetti width={width} height={height} />
        <h1 className="text-center font-bold text-4xl md:text-8xl uppercase">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-400 to-blue-500">
            It&apos;s a...{' '}
          </span>
          <span className="bg-none text-gray-900">BOY</span>
        </h1>
      </div>
    </div>
  )
}

export default Lol

export const lolPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
